import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { setLanguage } from "../../actions/app";
import colors from "../../colors";
import logo from "../../images/sinpin-logo-white.png";
import {
  cardsCollectionSelector,
  appTransactionsSelector,
} from "../../selectors";
import { withTranslation } from "react-i18next";
import { loggedOutThunk } from "../../actions/thunks";

class TopNavigation extends React.Component {
  state = {
    isOpen: false,
  };

  render() {
    const languageDropdown = (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {this.props.t("links.languages")}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              this.props.i18n.changeLanguage("en");
              this.props.setLanguage("en");
              this.setState({ isOpen: false });
            }}
          >
            {this.props.t("links.english")}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.props.i18n.changeLanguage("es");
              this.props.setLanguage("es");
              this.setState({ isOpen: false });
            }}
          >
            {this.props.t("links.spanish")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    return (
      <div
        style={{
          backgroundColor: colors.topNavBackground,
        }}
      >
        <Navbar color="dark" dark expand="md">
          <NavbarBrand
            tag={Link}
            to={this.props.isAuthenticated ? "/dashboard" : "/"}
            className="navbar-brand"
          >
            <img
              src={logo}
              alt="Sinpin Logo"
              className="img-fluid"
              style={{
                maxWidth: "180px",
              }}
            />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          />
          <Collapse isOpen={this.state.isOpen} navbar>
            {!this.props.isAuthenticated && (
              <Nav navbar>
                <NavLink
                  onClick={() => this.setState({ isOpen: false })}
                  to="/"
                  exact
                  className="nav-item nav-link"
                >
                  {this.props.t("links.home")}
                </NavLink>
                <a
                  onClick={() => this.setState({ isOpen: false })}
                  href="https://sinpin.com/home/rates"
                  target="_blank"
                  className="nav-item nav-link"
                  rel="noopener noreferrer"
                >
                  {this.props.t("links.rates")}
                </a>
              </Nav>
            )}
            {!this.props.isAuthenticated && (
              <Nav className="ml-auto" navbar>
                {languageDropdown}
                {/* Hiding Signup button due to fraud */}
                {/* <NavLink
                  onClick={() => this.setState({ isOpen: false })}
                  to="/signup"
                  className="nav-item nav-link"
                >
                  {this.props.t("links.signup")}
                </NavLink> */}
                {/* <NavLink
                  onClick={() => this.setState({ isOpen: false })}
                  to="/login"
                  className="nav-item nav-link"
                >
                  {this.props.t("links.login")}
                </NavLink> */}
              </Nav>
            )}

            {this.props.isAuthenticated && this.props.userHasCards && (
              <Nav navbar>
                <NavLink
                  onClick={() => this.setState({ isOpen: false })}
                  to="/cards"
                  exact
                  className="nav-item nav-link"
                >
                  {this.props.t("links.cards")}
                </NavLink>
              </Nav>
            )}

            {this.props.isAuthenticated && (
              <Nav navbar>
                <NavLink
                  to="/transactions"
                  exact
                  onClick={() => this.setState({ isOpen: false })}
                  className="nav-item nav-link"
                >
                  {this.props.t("links.userTransactions")}
                </NavLink>
              </Nav>
            )}

            {this.props.isAdmin && (
              <Nav navbar>
                <NavLink
                  onClick={() => this.setState({ isOpen: false })}
                  to="/admin"
                  exact
                  className="nav-item nav-link"
                >
                  {this.props.t("links.admin")}
                </NavLink>
              </Nav>
            )}
            {this.props.isAuthenticated && (
              <Nav className="ml-auto" navbar>
                {languageDropdown}
                <NavItem>
                  <a
                    role="button"
                    tabIndex={0}
                    className="nav-item nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.loggedOutThunk()}
                  >
                    {this.props.t("links.logout")}
                  </a>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

TopNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
  }).isRequired,
  userHasCards: PropTypes.bool.isRequired,
  userHasTransactions: PropTypes.bool.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.app.user,
    isAdmin: state.user.admin || false,
    userHasCards: cardsCollectionSelector(state).length > 0,
    userHasTransactions: appTransactionsSelector(state).length > 0,
  };
}

export default connect(mapStateToProps, { loggedOutThunk, setLanguage }, null, {
  pure: false,
})(withTranslation("translations")(TopNavigation));
