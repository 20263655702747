import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TopNavigation from "./layout/TopNavigation";
import MainPage from "./pages/MainPage";
// import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import CardPage from "./pages/CardPage";
import CardsPage from "./pages/CardsPage";
import TransactionsPage from "./pages/TransactionsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AdminPage from "./pages/AdminPage";
import AdminCountriesPage from "./pages/AdminCountriesPage";
import AdminNotificationsPage from "./pages/AdminNotificationsPage";
import AdminBlockedLists from "./pages/AdminBlockedLists";
import AdminPhoneIntelligencePage from "./pages/AdminPhoneIntelligencePage";
import AdminSearchDeviceIdsPage from "./pages/AdminSearchDeviceIdsPage";
import AdminDuplicateDeviceIdsPage from "./pages/AdminDuplicateDeviceIdsPage";
import SignupPage from "./pages/SignupPage";

const Routes = ({ user }) => {
  return (
    <div>
      <TopNavigation />

      {user._id && (
        <Switch>
          <Route path="/dashboard" exact>
            <DashboardPage />
          </Route>
          <Route path="/cards" exact>
            <CardsPage />
          </Route>
          <Route path="/transactions" exact>
            <TransactionsPage />
          </Route>
          <Route path="/cards/new" exact>
            <CardPage />
          </Route>
        </Switch>
      )}

      {user.admin && (
        <Switch>
          <Route path="/admin" exact>
            <AdminPage />
          </Route>
          <Route path="/admin/countries" exact>
            <AdminCountriesPage />
          </Route>
          <Route path="/admin/notifications" exact>
            <AdminNotificationsPage />
          </Route>
          <Route path="/admin/blocklists" exact>
            <AdminBlockedLists />
          </Route>
          <Route path="/admin/phoneintelligence" exact>
            <AdminPhoneIntelligencePage />
          </Route>
          <Route path="/admin/searchdeviceids" exact>
            <AdminSearchDeviceIdsPage />
          </Route>
          <Route path="/admin/duplicatedeviceids" exact>
            <AdminDuplicateDeviceIdsPage />
          </Route>
        </Switch>
      )}

      <Switch>
        <Route path="/" exact>
          <MainPage />
        </Route>
        <Route
          path="/reset_password/:token"
          exact
          component={ResetPasswordPage}
        />
        {/* <Route path="/login" exact>
          <LoginPage />
        </Route> */}
        {/* forcing to main page due to fraud */}
        {/* <Route path="/signup" exact>
          <SignupPage />
        </Route> */}
        <Route path="/signup" exact>
          <MainPage />
        </Route>
      </Switch>
    </div>
  );
};

export default Routes;
