import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isMobilePhone from "validator/lib/isMobilePhone";
import FormButton from "../elements/FormButton";
// import RestorePasswordForm from "./RestorePasswordForm";
import { withTranslation } from "react-i18next";
import { loggedInThunk } from "../../actions/thunks";
import { makeRequest } from "../../utils/request";

class LoginForm extends React.Component {
  state = {
    data: {
      phone: "",
      password: "",
      smsCode: "",
    },
    errors: {},
    loading: false,
    show: "login",
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      errors: nextProps.form.errors,
    });
  }

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmitCredentials = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    // blocking this due to fraud
    // if (Object.keys(errors).length === 0) {
    //   makeRequest("post", "/api/auth/login", { credentials: this.state.data })
    //     .then(() => {
    //       this.setState({ show: "sms" });
    //     })
    //     .catch((err) => this.setState({ errors: err.errors }));
    // }
  };

  onSubmitCode = (e) => {
    e.preventDefault();
    const errors = {};
    if (!this.state.data.smsCode || this.state.data.smsCode.length !== 6)
      errors.smsCode = "errors.invalidSMSCode";
    this.setState({ errors });
    // blocking this due to fraud
    // if (Object.keys(errors).length === 0) {
    //   this.setState({ loading: true });
    //   makeRequest("post", "/api/auth/verify", { user: this.state.data })
    //     .then((res) => {
    //       this.props.loggedInThunk(res.token);
    //     })
    //     .catch((err) => this.setState({ errors: err.errors, loading: false }));
    // }
  };

  validate = (data) => {
    const errors = {};
    if (!isMobilePhone(data.phone, "en-US")) errors.phone = "errors.phone";
    if (!data.password) errors.password = "errors.empty";
    return errors;
  };

  render() {
    const { data, errors } = this.state;
    const { loading } = this.props.form;
    const t = this.props.t;

    return (
      <div>
        {this.state.show === "login" && (
          <form onSubmit={this.onSubmitCredentials}>
            <div className="form-group">
              <label htmlFor="phone">{t("loginForm.phoneLabel")}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className={
                  errors.phone ? "form-control is-invalid" : "form-control"
                }
                onChange={this.onChangeString}
                value={data.phone}
                disabled={loading}
              />
              <div className="invalid-feedback form-text">
                {t(errors.phone)}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("loginForm.passwordLabel")}</label>
              <input
                type="password"
                id="password"
                name="password"
                className={
                  errors.password ? "form-control is-invalid" : "form-control"
                }
                onChange={this.onChangeString}
                value={data.password}
                disabled={loading}
              />
              <div className="invalid-feedback form-text">
                {t(errors.password)}
              </div>
            </div>

            <FormButton label={t("buttons.login")} loading={loading} />
            {/* <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ show: "restore" });
                }}
              >
                Forgot Password?
              </a>
            </div> */}
          </form>
        )}

        {this.state.show === "sms" && (
          <form onSubmit={this.onSubmitCode}>
            <div className="form-group">
              <label htmlFor="code">{t("loginForm.smsCodeLabel")}</label>
              <input
                type="text"
                id="code"
                name="smsCode"
                className={
                  errors.smsCode ? "form-control is-invalid" : "form-control"
                }
                onChange={this.onChangeString}
                value={data.smsCode}
                disabled={this.state.loading}
              />
              <div className="invalid-feedback form-text">
                {t(errors.smsCode)}
              </div>
            </div>

            <a
              role="button"
              tabIndex={0}
              onClick={() => this.setState({ smsSent: false })}
            >
              {t("loginForm.goBackLink")}
            </a>

            <FormButton
              label={t("buttons.verify")}
              loading={this.state.loading}
            />
          </form>
        )}

        {/* {this.state.show === "restore" && <RestorePasswordForm />} */}
      </div>
    );
  }
}

LoginForm.propTypes = {
  form: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.shape({}).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  loginSuccess: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    form: state.forms.login,
  };
}

export default connect(mapStateToProps, {
  loggedInThunk,
})(withTranslation("translations")(LoginForm));
