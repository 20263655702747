import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import sanityClient from "@sanity/client";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import phoneImage from "../../images/phone-sinpin-recharge.jpg";
import phoneImage2 from "../../images/phone-imtopup2.jpg";
import phoneImage3 from "../../images/phone-us-prepaid-wireless2.jpg";
import phoneImage4 from "../../images/phone-impayments2.jpg";
import downloadImage from "../../images/downloads-product-image-2.png";
import googlePlayImage from "../../images/google-play-350.png";
import appStoreImage from "../../images/appleapp-350.png";
import footerBackground from "../../images/downloads-parallax.jpg";
import Footer from "../layout/Footer";
import LoginForm from "../forms/LoginForm";
import "./mainPage.css";
import {
  SANITY_DATASET,
  SANITY_PROJECT_ID,
  SANITY_QUERIES,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";

const MainPage = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const client = sanityClient({
      projectId: SANITY_PROJECT_ID,
      dataset: SANITY_DATASET,
      useCdn: true,
    });
    client
      .fetch(SANITY_QUERIES.assets)
      .then((res) => setBackgroundImage(res[0].image));
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        className="row"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="col-md-8" />
        <div className="col-md-4">
          <div className="card m-5">
            <div className="card-body">
              {/* <LoginForm /> */}
              <p className="text-center mt-2">
                {/* forcing signup button to stay on home page due to fraud */}
                {/* open modal with message to download app */}
                {/* <Link to="/signup">{t("links.signup")}</Link> */}
                <Link to="/" onClick={toggleModal}>
                  {t("links.signup")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 pb-5">
          <div className="col-md-6 col-12">
            <Fade left>
              <img src={phoneImage} alt="" className="img-fluid" />
            </Fade>
          </div>
          <div className="col-md-6 col-12">
            <div
              dangerouslySetInnerHTML={{ __html: t("mainPage.firstSection") }}
            />
          </div>
        </div>

        <div className="row mt-5 pb-5">
          <div className="col-md-6 col-12 order-md-1 order-2">
            <div
              dangerouslySetInnerHTML={{
                __html: t("mainPage.secondSection"),
              }}
            />
          </div>
          <div className="col-md-6 col-12 order-md-2 order-1">
            <Fade right>
              <img src={phoneImage2} alt="" className="img-fluid" />
            </Fade>
          </div>
        </div>

        <div className="row mt-5 pb-5">
          <div className="col-md-6 col-12">
            <Fade left>
              <img src={phoneImage3} alt="" className="img-fluid" />
            </Fade>
          </div>
          <div className="col-md-6 col-12">
            <div
              dangerouslySetInnerHTML={{ __html: t("mainPage.thirdSection") }}
            />
          </div>
        </div>

        <div className="row mt-5 pb-5">
          <div className="col-md-6 col-12 order-md-1 order-2">
            <div
              dangerouslySetInnerHTML={{
                __html: t("mainPage.fourthSection"),
              }}
            />
          </div>
          <div className="col-md-6 col-12 order-md-2 order-1">
            <Fade right>
              <img src={phoneImage4} alt="" className="img-fluid" />
            </Fade>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundImage: `url(${footerBackground})`,
        }}
      >
        <div className="col-md-6 col-12">
          <Fade right>
            <img
              style={{ padding: "75px 0 0 150px" }}
              src={downloadImage}
              alt=""
            />
          </Fade>
        </div>
        <div className="col-md-6 col-12 text-white">
          <div
            style={{
              padding: "200px 0 0 0",
            }}
          >
            <h2 className="header">Downloads</h2>
            <p>
              App available for <b>Android and iPhone</b>
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.sinpin.customerapp">
              <img
                width="200px"
                src={googlePlayImage}
                alt="Google Play"
                className="img-fluid"
              />
            </a>
            &nbsp; &nbsp;
            <a href="https://itunes.apple.com/us/app/sin-pin-recharge/id1439216848?ls=1&mt=8">
              <img
                width="200px"
                src={appStoreImage}
                alt="Apple Store"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} size="lg">
        <ModalHeader toggle={toggleModal}>DOWLOAD THE APP!!</ModalHeader>
        <ModalBody>
          <p>Get all the latest features with SIN PIN Mobile App!!</p>
          <p>Available on Android and iOS</p>
          <div className="row">
            <div className="col-12 text-white">
              <div
                style={{
                  padding: "0 0 0 0",
                }}
              >
                <a href="https://play.google.com/store/apps/details?id=com.sinpin.customerapp">
                  <img
                    width="200px"
                    src={googlePlayImage}
                    alt="Google Play"
                    className="img-fluid"
                  />
                </a>
                &nbsp; &nbsp;
                <a href="https://itunes.apple.com/us/app/sin-pin-recharge/id1439216848?ls=1&mt=8">
                  <img
                    width="200px"
                    src={appStoreImage}
                    alt="Apple Store"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Footer />
    </div>
  );
};

export default MainPage;
